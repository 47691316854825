<template>
  <v-container id="user-table" fluid tag="section">
    <base-panel icon="mdi-account-supervisor" :title="$t('administrators.page title')">
      <users-form ref="editForm" :data="meta" @itemSaved="onSaved"></users-form>

      <delete-dialog ref="deleteConfirm" :url="apiQuery" :title="$t('administrators.Delete user')"
        :confirmation="$t('administrators.deleted')" @itemDeleted="onDeleted">
        <template v-slot:default="item">{{ $t('administrators.Confirm delete', item) }}</template>

        <template v-slot:error="error">
          <v-alert v-if="error.message" tile class="mb-0" type="error">{{
      error.message
    }}</v-alert>
        </template>

        <template v-slot:confirmed="item">
          <v-alert v-if="item.id" tile type="success" class="mb-0">{{ $t('administrators.deleted') }}</v-alert>
        </template>
      </delete-dialog>

      <v-data-table :headers="headers" :items="items" :options.sync="options" :server-items-length="total"
        :loading="loading || !meta" multi-sort :footer-props="{
      'items-per-page-options': [20, 50, 100],
    }">
        <template v-slot:[`item.id`]="{ item }">
          <v-chip v-if="item.isNew" color="green">{{ item.id }}</v-chip>
          <span v-else>{{ item.id }}</span>
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer />
            <v-btn color="primary" dark class="mb-2" @click="onAdd">
              <v-icon left>mdi-account-plus</v-icon>
              {{ $t("administrators.Add user") }}
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="onEdit(item)">mdi-pencil</v-icon>
          <v-icon small @click="onDelete(item)">mdi-delete</v-icon>
        </template>

        <template v-slot:[`item.roles`]="{ item }">
          <span v-if="meta">
            <v-chip v-for="role in item.roles" :key="'role' + role" small
              :class="role === 1 ? 'red' : role === 2 ? 'orange' : ''">{{ meta.roles[role] }}</v-chip>
          </span>
        </template>
      </v-data-table>
    </base-panel>
  </v-container>
</template>

<script>
import crudMixin from "mixins/crudTable";
import UsersForm from "./UsersForm";
import DeleteDialog from "@/components/crud/DeleteDialog";

export default {
  components: {
    UsersForm,
    DeleteDialog,
  },
  mixins: [crudMixin],
  data() {
    return {
      apiQuery: "/admins/",
      headers: [
        { text: "ID", align: "start", value: "id" },
        { text: this.$t("Name"), value: "name" },
        { text: this.$t("Email"), value: "email" },
        { text: this.$t("Roles"), value: "roles" },
        { text: this.$t("Actions"), value: "actions", sortable: false },
      ],
    };
  },
  created() {
    this.loadMeta();
  },
  methods: {
    onSaved(item, index) {
      if (index === -1) {
        this.total++;
        item.isNew = true;
        this.items.push(item);
        window.setTimeout(() => {
          this.$refs.editForm.clearForm();
        }, 1500);
      } else {
        Object.assign(this.items[index], item);
        window.setTimeout(() => {
          this.$refs.editForm.closeForm();
        }, 2500);
      }
    },
  },
};
</script>
